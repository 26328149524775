@import '../../../node_modules/react-image-crop/src/ReactCrop.scss';
@import 'node_modules/@varymadellc/my-weed-club-component-library/dist/style.css';

@tailwind base;
@tailwind components;

.active-queue-item {
  border: 2px solid var(--site-colors-hilight) !important;
}

.rangeSlider {
  z-index: 1;
  position: relative;
  width: 100%;
  appearance: none;
  max-height: 4px !important;
  background-color: var(--site-colors-black);
  padding: 0 !important;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--site-colors-primary) !important;
  background-color: var(--site-colors-primary, #000);
  cursor: pointer;
  border: 1px solid var(--site-colors-white);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.rangeSlider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: var(--site-colors-black, #000);
  cursor: pointer;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--site-colors-white) var(--site-colors-black);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  //width: 20px;
}

*::-webkit-scrollbar-track {
  background: var(--site-colors-black);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--site-colors-white);
  border-radius: 14px;
}

input {
  color-scheme: dark;
}


.ql-indent-1 {
  margin-left: 2rem;
}

.ql-indent-2 {
  margin-left: 4rem;
}

.ql-indent-3 {
  margin-left: 6rem;
}

.ql-indent-4 {
  margin-left: 8rem;
}

.ql-indent-5 {
  margin-left: 10rem;
}

.ql-indent-6 {
  margin-left: 12rem;
}

.ql-indent-7 {
  margin-left: 14rem;
}

.ql-indent-8 {
  margin-left: 16rem;
}

.ql-tooltip.ql-editing input {
  color: var(--site-colors-white);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--site-colors-white) var(--site-colors-dark-gray);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background: var(--site-colors-dark-gray);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--site-colors-white);
  border-radius: 14px;
}

html {
  position: relative;
}

body {
  color: var(--site-colors-white);
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  line-height: 2rem;
  background-repeat: no-repeat;
  background-size: 100vw;
  background-image: linear-gradient(90deg, var(--site-colors-primary), white);
  background-color: var(--site-colors-secondary, #3A4C63);
  background-attachment: fixed;
  overflow-x: hidden;
  min-height: 100%;
}

strong, b {
  font-weight: bold !important;
}

ul {
  list-style: disc;
  margin-left: 2rem;
}

ol {
  list-style: decimal;
  margin-left: 2rem;
}

h1 {
  font-size: 1.7rem;
  margin-bottom: 15px;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

small {
  font-size: 0.8rem;
  line-height: 1rem;
}

p {
  line-height: 1.8rem;
}

a, a:active {
  color: var(--site-colors-white);
}

hr {
  border: var(--site-colors-dark-gray) solid 1px;
}

.text-white > a {
  font-weight: 400;
  color: var(--site-colors-white);
  text-decoration: underline;
}

.input-checkbox {

}

.hover\:pointer {
  cursor: pointer;
}

.debug {
  background-color: rgba(255, 0, 0, 0.3);
  border: 1px solid rgba(255, 0, 0, 1);
}

.bg-gradient {
  background-image: linear-gradient(180deg, rgba(var(--site-colors-white), 0.2), transparent);
}

.my-weed-diary-bg-lights {
  background-image: radial-gradient(var(--site-colors-primary), transparent 70%, transparent 100%);
}

.card-flip {
  transform: rotateY(180deg);
}

.card-flip-reverse {
  transform: rotateY(-180deg);
}

.no-backface {
  backface-visibility: hidden;
}

.modal-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  z-index: 9;
}

.myChartWrapper div div .tick text {
  transform: translateY(20px) rotate(45deg);
}

.myChartWrapper {
  text {
    color: var(--site-colors-white);
    fill: var(--site-colors-white);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0.7;
}

.modal-content {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50.1%, -50.1%);
  background-color: var(--site-colors-secondary);
  color: var(--site-colors-white);
  height: auto;
  width: 96%;
  max-height: 90%;
  border-radius: 8px;
  overflow: auto;
  padding: 1rem 10px 10px;
  max-width: 1200px;
  min-width: 320px;
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: var(--site-colors-primary);
  color: var(--site-colors-black);
  border-radius: 0 0 0 20%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  &.hasTransparentBackground {
    color: var(--site-colors-white);
  }
}

.modal-primary {
  position: relative;
  background-color: var(--site-colors-black);
  color: var(--site-colors-white);
  border-radius: 1rem;
  padding: 1rem;
  border: 2px solid var(--site-colors-primary, #ffc800);;
}

.modal-secondary {
  background-color: var(--site-colors-white);
  color: var(--site-colors-black);
  border-radius: 1rem;
  padding: 1rem;
  border: 2px solid var(--site-colors-primary, #ffc800);;
}

.side-button {
  display: flex;
  font-size: 1.2rem;
  flex: 0 0 auto;
  flex-flow: row-reverse nowrap;
  position: absolute;
  top: 10px;
  right: 0;
  background-color: var(--site-colors-primary, #ffc800);;
  border-radius: 20px 0 0 20px;

  &.left, &.left > div {
    left: 0 !important;
    right: auto !important;
    border-radius: 0 20px 20px 0 !important;
  }

  > div {
    color: var(--site-colors-black);
    padding: 5px 10px 5px 15px;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  > div {
    padding: 0 10px;
    position: relative;
    border-radius: 20px 0 0 20px;
  }

  > div:first-child {
    background-color: var(--site-colors-primary, #ffc800);;
    z-index: 3;
  }

  > div:nth-child(2) {
    background-color: var(--site-colors-secondary, #3A4C63);
    margin-right: -15px;
    z-index: 1;
    padding-right: 20px;
  }

  > div:nth-child(3) {
    background-color: var(--site-colors-tertiary, #ceb668);
    margin-right: -15px;
    z-index: 0;
    padding-right: 20px;
  }
}

// HIDE STUPID WHITE BACKGROUND ON AUTO COMPLETE
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}

.ce-toolbar__settings-btn, .ce-toolbar__plus {
  background-color: var(--site-colors-white);
}

.ce-popover__item-label, .ce-popover__item-icon {
  color: var(--site-colors-black);
  fill: var(--site-colors-black);
}

.bg-semi-transparent {
  background-color: rgba(var(--site-colors-black), 75%) !important;
}

label {
  margin-bottom: 0;
}

// special badges
.badge-tag {
  padding: 2px 4px;
  color: var(--site-colors-black);
  position: relative;
  width: fit-content;
  right: 0;
  background-color: var(--site-colors-white);;
}

.badge-bottom-tag {
  font-size: 0.7rem;
  padding: 2px 4px;
  color: var(--site-colors-black);
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--site-colors-white);
  word-break: keep-all;
  white-space: nowrap;
}

.badge-tag-featured {
  // TODO implement this badge in code as a feature as it's not currently existing but it's an idea.
  background-color: var(--site-colors-tertiary, #ceb668);
}

.badge-tag-looking-for-work::before {
  content: '🧳';
  font-size: 0.9rem;
  padding-right: 0.4rem;
}

.badge-tag-looking-for-work {
  display: flex;
  background-image: radial-gradient(circle at 50% 50%, #51ab51, #056405);
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  white-space: break-spaces;
  font-size: 0.9rem;
}

.badge-tag-first {
  background-color: var(--site-colors-tertiary, #ceb668);
  color: var(--site-colors-black);
}

.badge-tag-brand {
  padding: 2px 10px;
  color: var(--site-colors-black);
  background-color: #ceb668;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.badge-tag-brand::after {
  content: '🔥';
  font-size: 0.9rem;
  padding-left: 0.4rem;
}

.badge-tag-weed-wizard {
  background: linear-gradient(
                  120deg,
                  #ffe700, /* Light Purple */
                  #a974d9, /* Medium Purple */
                  #00fdcf, /* Dark Purple */
                  #a974d9, /* Medium Purple */
                  #ffe700 /* Light Purple */
  );
}

.badge-tag-team-member {
  background: linear-gradient(
                  120deg,
                  #d3b6e3, /* Light Purple */
                  #a974d9, /* Medium Purple */
                  #7f4ab1, /* Dark Purple */
                  #a974d9, /* Medium Purple */
                  #d3b6e3 /* Light Purple */
  );
}

.badge-tag-first-100 {
  background: linear-gradient(
                  120deg,
                  #FFD700, /* Gold */
                  #FFFF00, /* Yellow */
                  #C0C0C0, /* Silver */
                  #FFFF00, /* Yellow */
                  #FFD700 /* Gold */
  );
}

.badge-tag-lunch-time-dab-time {
  display: flex;
  background-size: cover;
  background-image: url('/imgs/badges/lunchtimedabtime.png');
  width: 100%;
  height: 100%;
  border-radius: 0;
  justify-content: center;
  align-items: center;

  &:hover {
    overflow: visible;
  }

  & > a {
    color: black;
    background-color: white;
    border-radius: 1rem;
    padding: 2px 10px;
  }
}

.badge-tag-blogger {
  color: white;
  font-weight: normal;
  background: linear-gradient(
                  120deg,
                  #7b00ff,
                  #ff00ff,
                  #ff7f00,
                  #ffff00,
                  #ff0000,
                  #7b00ff
  );
}

.badge-tag-blogger::after {
  content: '📝';
  padding-left: 0.4rem;
  line-height: 0.7rem;
  font-size: 0.7rem;
  width: 10px;
  height: 10px;
}

.badge-tag-first-100, .badge-tag-team-member, .badge-tag-blogger, .badge-tag-weed-wizard {
  background-size: 200% 100%;
  animation: shimmerGold 5s infinite;
  font-size: 0.9rem;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-weight: bold;
}

@keyframes shimmerGold {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.animate-smooth {
  transition: all 1s ease-in-out;
}

.ql-toolbar {
  background-color: #fff;
}

.stuck {
  position: fixed;
  top: 0;
  z-index: 9999;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}

button {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}

button:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

.animate-bounce-once {
  // animate once and then stop
  animation: bounce 1s;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-20);
  }
  40% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes stamp {
  0% {
    transform: scale(0.8) rotate(0deg);
  }
  25% {
    transform: scale(1.2) rotate(-5deg);
  }
  50% {
    transform: scale(0.9) rotate(0deg);
  }
  75% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.slide-in {
  @media (min-width: 768px) {
    transition: all 0.5s ease-in-out;
  }
}

.animate-stamp {
  animation: stamp 1s ease-in-out infinite forwards;
  transform-origin: center;
  animation-iteration-count: 1
}

.line-height-0 {
  line-height: 0;
}

.badge-carousel-slot:hover {
  overflow: visible;
}

.animate-top {
  transition: top 0.5s ease-in-out;
}

.custom-audio-player {
  input[type=range] {
    accent-color: var(--site-colors-primary, #ffc800);;
    background: var(--site-colors-dark-gray);
    border: 0;
    border-radius: 8px;
    outline: none;
    -webkit-appearance: none;
  }
}

svg.color-override {
  fill: var(--site-colors-white) !important;
  color: var(--site-colors-white) !important;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.react-datepicker__header__dropdown {
  line-height: 1.5rem;
}

.react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
  width: 6px;
  height: 6px;
  border-width: 2px 2px 0 0;
}

.react-datepicker__navigation-icon--previous::before, .react-datepicker__navigation-icon--next::before {
  transform: initial translateY(-50%);
  position: absolute;
  top: 50%;
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.current-page {
  background-color: var(--site-colors-secondary);
  color: var(--site-colors-white) !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.bg-pattern-1 {
  --s: 14px; /* control the size*/
  --c1: #a6cb86;
  --c2: #6a8568;

  --_g: radial-gradient(#0000 60%, var(--c1) 61% 63%, #0000 64% 77%, var(--c1) 78% 80%, #0000 81%);
  --_c:, #0000 75%, var(--c2) 0;
  background: conic-gradient(at 12% 20% var(--_c)) calc(var(--s) * .44) calc(.9 * var(--s)),
  conic-gradient(at 12% 20% var(--_c)) calc(var(--s) * -.06) calc(.4 * var(--s)),
  conic-gradient(at 20% 12% var(--_c)) calc(.9 * var(--s)) calc(var(--s) * .44),
  conic-gradient(at 20% 12% var(--_c)) calc(.4 * var(--s)) calc(var(--s) * -.06),
  var(--_g), var(--_g) calc(var(--s) / 2) calc(var(--s) / 2) var(--c2);
  background-size: var(--s) var(--s);
}

.bg-pattern-2 {
  --s: 34px; /* control the size*/
  --c1: #a6cb86;
  --c2: #6a8568;

  background: radial-gradient(var(--c2) 15%,
          #0000 17% 20%, var(--c2) 22% 25%,
          #0000 27% 30%, var(--c2) 32% 35%,
          #0000 37% 40%, var(--c2) 42% 45%,
          #0000 47% 50%, var(--c2) 52% 55%, var(--c1) 57%) calc(var(--s) / 2) 0/var(--s) var(--s),
  repeating-conic-gradient(var(--c2) 0 25%, var(--c1) 0 50%) 0 0/calc(2*var(--s)) calc(2 * var(--s));
}

.bg-pattern-3 {
  background-image: url('/imgs/bg-pattern.png');
  background-size: 25px;
  background-repeat: repeat;
}

.bg-coming-soon {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-coming-soon > div {
  border: 2px solid var(--site-colors-tertiary);
  font-size: 1.4rem;
  color: var(--site-colors-white);
  display: flex;
  justify-content: center;
  background-color: red;
  background-image: repeating-linear-gradient(
                  135deg,
                  yellow,
                  yellow 10px,
                  black 10px,
                  black 20px
  );
}

.bg-coming-soon > div > div {
  background-color: var(--site-colors-tertiary);
  padding: 10px;
  border-radius: 10px;
}

.code::before {
  content: '🚀 ';
}

.code {
  background-color: var(--site-colors-dark-gray);
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--site-colors-white);
  text-align: center;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

/* Add this to your CSS */
.rainbow-hover {
  border: 3px solid transparent;
  background-size: cover;
  transition: background-position 0.5s ease-in-out;
}

.rainbow-hover:hover {
  border: 3px solid yellow;
  background-position: center;
  animation: rainbow-glitter 2s infinite linear;
}

.rainbow-hover:hover::before {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%);
}

@keyframes rainbow-glitter {
  0% {
    filter: hue-rotate(0deg) contrast(1.5) brightness(1.3);
  }
  50% {
    filter: hue-rotate(180deg) contrast(1.5) brightness(1.3);
  }
  100% {
    filter: hue-rotate(360deg) contrast(1.5) brightness(1.3);
  }
}

.btn-link {
  padding: 0.25rem 1rem;
  border-radius: 5px;
}

.firstDiv > div:not(.keeper)::after {
  content: '';
  display: block;
  height: 50px;
  width: 100%;
  background-color: pink;
}

.keeper {
  border: 10px solid yellow;
  margin-bottom: 50px;
}

@tailwind utilities;
